<template>
  <div class="member-detail-container">
    <v-title>
      <Breadcrumb separator=">">
        <Breadcrumb-item><i class="icon-home"></i>会员档案</Breadcrumb-item>
        <Breadcrumb-item>会员详情</Breadcrumb-item>
      </Breadcrumb>
    </v-title>

    <div class="content">
      <v-member-panel></v-member-panel>
      <v-member-menu></v-member-menu>
      <router-view></router-view>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import vTitle from 'components/topHeader/topHeader';
import vMemberPanel from 'pages/member/common/member-fixed-panel';
import vMemberMenu from 'pages/member/common/member-menu';
export default {
	components: {
		'v-title': vTitle,
		'v-member-panel': vMemberPanel,
		'v-member-menu': vMemberMenu,
	},
};
</script>
<style lang="css" scoped>
</style>
