<template>
  <div class="member-menu-contain">
    <ul class="horizontal-nav">
      <li>
        <router-link :to="{name: 'personIndex'}">咨询促进</router-link>
      </li>
      <li>
        <router-link :to="{name: 'card'}">已购卡系</router-link>
      </li>
      <li>
        <router-link :to="{name: 'memberInfo', params: {id: 1}}">会员信息</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/3/'}">疾病信息</router-link>
      </li>
      <li>
        <router-link :to="{name: 'Drug'}">直接健康</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/5'}">监测数据</router-link>
      </li>
      <li>
        <router-link :to="{name: 'memberReport'}">报告</router-link>
      </li>
      <li>
        <router-link :to="{name: 'PunchIn'}">打卡进店</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/Sports'}">运动记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/reconstru'}">门店复健</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/class'}">课程活动记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/7'}">膳食记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/Train'}">训练记录</router-link>
      </li>
      <li>
        <router-link :to="{name: 'PlanRecord'}">方案记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/Services'}">促进记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/Linghuo'}">领货记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/Problem'}">问题记录</router-link>
      </li>
      <li>
        <router-link :to="{path: '/base/member/detail/' + memberId + '/watch'}">生命线</router-link>
      </li>
    </ul>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
};
</script>
<style lang="css" scoped>
.member-menu-contain {
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
  height: 45px;
  overflow-y: hidden;
}
.horizontal-nav {
  height: 44px;
  background: #f3f3f3;
  border: 1px solid #e3e3e3;
  overflow: auto hidden;
  white-space: nowrap;
}
.horizontal-nav>li {
  display: inline-block;
  height: 44px;
  line-height: 44px;
}
.horizontal-nav>li>a {
  display: inline-block;
  padding: 0 20px;
  color: #999;
}
.horizontal-nav>li>a.active {
  color: #fff;
  background-color: #fdad00;
}
.statistics-container .content-in {
  padding: 20px;
  background-color: #fff;
}
</style>
